





















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { DocumentReference } from '@firebase/firestore-types';

import {
  ScreenCluster,
  SystemStatus,
  Campaign,
  ClustersSchedulesRecord,
  CompanyProfile,
  Nullable,
} from '@/app/shared/utilities/static-types';
import { getCompanyProfileByUserIdAction } from '@globalActions/CompanyProfileActions';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import namespace from '@/app/shared/store/namespace';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import ClustersAndSchedules from '@/app/shared/components/ClustersAndSchedules/ClustersAndSchedules.vue';

import { cloneDeep } from 'lodash';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { approveCampaignAction, rejectCampaignAction } from '../../shared/actions';

@Component({
  components: {
    FormComponent,
    PopupMessage,
    ClustersAndSchedules,
  },
})
export default class CampaignEdit extends Vue {
  @Getter('getCurrentStoreCampaignRef', { namespace: namespace.CampaignModule })
  public currentRef!: DocumentReference;
  public adminPendingCampaignsRoute = AdminRoutes.STORE_PENDING_CAMPAIGNS;

  public campaignName = '';

  public clustersSchedulesRecord: ClustersSchedulesRecord = {};
  public campaignClustersSchedules: ClustersSchedulesRecord = {};
  public screenClustersItems: ScreenCluster[] = [];
  public preSelectedScreenClusters: ScreenCluster[] = [];

  public campaignId = '';
  public advertiserId = '';

  public comments = '';
  public commentsErrors: string | string[] = [];

  public campaignStatus: Nullable<SystemStatus> = null;
  public campaign: Nullable<Campaign> = null;
  public mediaFile: any = null;
  public companyProfile: Nullable<CompanyProfile> = null;
  public createdByStoreOwner: boolean = false;

  public rejected = true;

  public showConfirmationMessage: (() => Promise<any>) | null = null;

  public goBack(): void {
    this.$router.go(-1);
  }

  public async loadCampaign() {
    if (!this.currentRef) {
      this.$router.push(this.adminPendingCampaignsRoute);
      throw new Error('Error loading campaign.');
    }
    const campaignDoc = await this.currentRef.get();
    const campaign = campaignDoc.data() as Campaign;
    this.campaign = campaign;
    this.advertiserId = campaign!.ADVERTISER_UID!;
    this.createdByStoreOwner = Boolean(campaign!.CREATED_BY_STORE_OWNER);
    if (this.advertiserId) {
      this.companyProfile = await getCompanyProfileByUserIdAction(this.advertiserId);
    }
    this.campaignId = campaign!.ID;
    this.campaignName = campaign!.NAME;

    this.campaignClustersSchedules = campaign!.SELECTED_CLUSTERS_SCHEDULES;
    this.clustersSchedulesRecord = cloneDeep(this.campaignClustersSchedules);
    this.screenClustersItems = campaign!.SCREEN_CLUSTERS;
    this.preSelectedScreenClusters = cloneDeep(this.screenClustersItems);

    this.mediaFile = campaign!.MEDIA_FILE;

    this.campaignStatus = campaign!.STATUS;
    this.comments = campaign!.COMMENTS!;
  }

  public async updateCampaign() {
    if (this.rejected) {
      return this.rejectCampaign();
    }
    return this.approveCampaign();
  }

  public async approveCampaign() {
    try {
      await this.showConfirmationMessage!();
    } catch (err) {
      throw new Error('Campaign approval not confirmed');
    }

    return approveCampaignAction(this.campaign!);
  }

  public async rejectCampaign() {
    if (!this.comments) {
      this.commentsErrors = 'Required for rejection.';
      throw new Error('You must add comments in case of rejection.');
    }
    return rejectCampaignAction({ ...this.campaign, COMMENTS: this.comments } as Campaign);
  }

  @Watch('comments')
  public onCommentsChanged(val: string, oldVal: string) {
    if (this.comments) {
      this.commentsErrors = [];
    }
  }

  private get campaignRef(): DocumentReference {
    return FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(this.advertiserId)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .doc(this.campaignId);
  }
}
